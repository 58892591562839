@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Montserrat', sans-serif;
}

.app {
    position: relative;
    overflow-x: hidden;
}

html,
body {
    scroll-behavior: smooth;
}

canvas {
    position: absolute;
}